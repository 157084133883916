import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  useForm,
  Textarea,
  Select,
} from "ebs-design";

import { cohorts } from "api";
import { AlertErrors } from "components";
import { NotificationsType, SendNotificationType } from "types";
import { mapToSelectOptions } from "utils";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  cohortId?: number;
}

const SendNotification = ({ isOpen, onCancel, cohortId }: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const notificationTypesOptions = mapToSelectOptions(
    Object.keys(NotificationsType).map((type) => ({
      title: type,
      id: type,
    })),
  );

  const { mutate, isLoading, error, reset } = useMutation<
    SendNotificationType,
    AxiosError,
    SendNotificationType
  >((notification) => cohorts.sendNotification(cohortId, notification), {
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries("cohorts");
    },
  });

  const onCloseModal = () => {
    onCancel();
    form.resetFields();
    reset();
  };

  const onSubmit = (formValues: SendNotificationType) => mutate(formValues);

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      title="Send notification"
      size="small"
    >
      <Modal.Content>
        <AlertErrors error={error!} />

        <Form form={form} onFinish={onSubmit}>
          <Form.Field name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field
            name="body"
            label="Body"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Textarea />
          </Form.Field>

          <Form.Field name="types" label="Types">
            <Select
              placeholder="Select notification type"
              loading={isLoading}
              options={notificationTypesOptions}
              mode="multiple"
            />
          </Form.Field>

          <Form.Field name="action_name" label="Action name">
            <Input />
          </Form.Field>

          <Form.Field name="action_link" label="Action url">
            <Input />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCloseModal}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={form.submit}
          >
            Send
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default SendNotification;

import { DatePicker, Label, Space } from "ebs-design";
import { GenericObject } from "types";
import { cohortSuffixMappings, getCohortFieldsParts } from "../utils";
import { DateType } from "ebs-design/dist/components/molecules/DatePicker/types";

export interface Props {
  formValue: GenericObject;
  option: GenericObject;
  onChange: (value: GenericObject) => void;
}

export const DateInputElement = ({ option, formValue, onChange }: Props) => {
  const onSelectDate = (date: DateType) => {
    const updatedFormValue = { ...formValue };

    if (date) {
      updatedFormValue[option?.value] = [date];
    }
    onChange(updatedFormValue);

    if (!date) {
      onChange([]);
    }
  };

  return (
    <Space justify="space-between">
      <Label
        text={
          cohortSuffixMappings?.[
            getCohortFieldsParts(option?.value)
              ?.suffix as keyof typeof cohortSuffixMappings
          ]?.label || option?.value
        }
      />

      <DatePicker
        isClearable
        onChange={(date) => onSelectDate(date)}
        value={formValue?.[option?.value]?.[0]}
      />
    </Space>
  );
};

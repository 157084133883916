import { Checkbox } from "ebs-design";
import { GenericObject } from "types";

export interface Props {
  formValue: GenericObject[];
  option: GenericObject;
  getValue?: (value: GenericObject) => void;
  onChange: (value: GenericObject) => void;
}

export const CheckBoxElement = ({
  formValue,
  option,
  getValue = (value) => value,
  onChange,
}: Props) => {
  const onChangeCheckBox = (value: boolean) => {
    value
      ? onChange([...(formValue || []), option.value])
      : onChange(
          formValue?.filter(
            (v: GenericObject) => getValue?.(v) !== getValue?.(option?.value),
          ) || [],
        );
  };

  const checkboxValue = !!formValue?.find?.(
    (checkedValue: GenericObject) =>
      getValue?.(checkedValue) === getValue?.(option.value),
  );

  return (
    <Checkbox
      checked={checkboxValue}
      onChange={onChangeCheckBox}
      value={option?.value}
      text={option?.text}
    />
  );
};

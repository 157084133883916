import React from "react";
import { Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { GenericObject } from "types";

import { CheckBoxElement } from "./CheckBoxElement";
import { DateInputElement } from "./DateInputElement";
import { InputNumberElement } from "./InputNumberElement";
import { countInputKeys, dateInputKeys } from "../nameMaps";

const CHECKBOX = "checkbox";

const ElementComponents = {
  [CHECKBOX]: CheckBoxElement,
  ...Object.fromEntries(dateInputKeys.map((key) => [key, DateInputElement])),
  // ...Object.fromEntries(rangeInputKeys.map((key) => [key, RangeInputElement])),
  ...Object.fromEntries(countInputKeys.map((key) => [key, InputNumberElement])),
  // ...Object.fromEntries(
  //   countRangeInputKeys.map((key) => [key, InputNumberRangeElement]),
  // ),
};

export interface DynamicListFormFieldProps extends FormFieldProps {
  options?: { text: React.ReactNode; value: string | number }[];
  getValue?: (value: GenericObject) => void;
}

export const CohortDynamicForm = ({
  options,
  getValue = (value) => value,
  ...props
}: DynamicListFormFieldProps) => {
  return (
    <Form.Field {...props}>
      {({ value, onChange }) => {
        return (
          <div className="display-grid grid-gap-4">
            {options?.map((option) => {
              const formComponentKey =
                option?.value in ElementComponents ? option.value : CHECKBOX;

              const FormComponent =
                ElementComponents[
                  formComponentKey as keyof typeof ElementComponents
                ];

              return (
                <FormComponent
                  onChange={onChange}
                  formValue={value}
                  option={option}
                  key={option?.value}
                />
              );
            })}
          </div>
        );
      }}
    </Form.Field>
  );
};

import { CohortParameters } from "types";

import {
  genderNameMap,
  ageRangeNameMap,
  finantialMetricsKeyNameMap,
  finantialMetricsRangeNameMap,
  freeGiftsRangeNameMap,
  invitesRangeNameMap,
  inAppMetricsKeyNameMap,
  inAppMetricsRangeNameMap,
  campaignMetricsKeyNameMap,
  campaignMetricsRangeNameMap,
} from "./nameMaps";
import {
  MappedListParam,
  ListParam,
  MappedListPackParam,
} from "./ParamComponents";

export interface CohortParametersDetailsProps {
  parameters?: CohortParameters;
}

export const CohortParametersDetails = ({
  parameters,
}: CohortParametersDetailsProps) => {
  return (
    <>
      <MappedListParam
        title="Gender"
        map={genderNameMap}
        values={parameters?.gender}
      />

      <MappedListParam
        title="Age"
        map={ageRangeNameMap}
        values={parameters?.age}
      />

      <ListParam
        title="Location"
        values={parameters?.location?.map((n) => n?.name)}
      />

      <MappedListPackParam
        title="Financial metrics"
        keyMap={finantialMetricsKeyNameMap}
        map={finantialMetricsRangeNameMap}
        valuesPack={parameters?.financial_metrics}
      />

      <MappedListParam
        title="Free gifts"
        map={freeGiftsRangeNameMap}
        values={parameters?.free_gifts}
      />

      <MappedListParam
        title="Invites"
        map={invitesRangeNameMap}
        values={parameters?.invites}
      />

      <ListParam
        title="Favourite brands"
        values={parameters?.favourite_brands}
      />

      <MappedListPackParam
        title="In app metrics"
        keyMap={inAppMetricsKeyNameMap}
        map={inAppMetricsRangeNameMap}
        valuesPack={parameters?.in_app_metrics}
      />

      <ListParam title="Interests" values={parameters?.interests} />

      <ListParam
        title="Business companies"
        values={parameters?.business_companies?.map(
          (company) => `${company?.name} (${company?.owner?.email})`,
        )}
      />

      <MappedListPackParam
        title="Campaign metrics"
        keyMap={campaignMetricsKeyNameMap}
        map={campaignMetricsRangeNameMap}
        valuesPack={parameters?.campaign_metrics}
      />
    </>
  );
};

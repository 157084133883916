import { Link } from "react-router-dom";
import { Button, Space, Tooltip } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";
import { Icon } from "components";
import { CohortEntity, CohortParameters } from "types";
import { CohortParametersDetails } from "../CohortParametersDetails";
import { ModuleAction } from "features/modules-permissions/ModuleAction";
import { CohortStatusType } from "../utils";

export const cohortsTableColumns = (
  setSendNotificationCohortId: (id: number) => void,
): ColumnType<CohortEntity>[] => [
  {
    title: "Name",
    dataIndex: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Parameters",
    dataIndex: "parameters",
    render: (parameters: CohortParameters) => (
      <Tooltip
        placement="auto"
        trigger="hover"
        tooltip={<CohortParametersDetails parameters={parameters} />}
        inline
        width={300}
      >
        <Icon className="rotate-90" type="moreactions" />
      </Tooltip>
    ),
  },
  {
    title: "Number of users",
    dataIndex: "users_count",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) =>
      CohortStatusType[status.toUpperCase() as keyof typeof CohortStatusType],
  },
  {
    title: "",
    width: "150px",
    dataIndex: "id",
    render: (id: number) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <Space justify="center">
            <ModuleAction module="cohorts">
              <Tooltip placement="top-end" title="Edit cohort">
                <Link to={`/cohorts/${id}/edit`}>
                  <Icon type="edit" />
                </Link>
              </Tooltip>
            </ModuleAction>

            <ModuleAction module="send_gift">
              <Tooltip placement="top-end" title="Send gift">
                <Link to={`/gifts/send/?cohort_id=${id}`}>
                  <Icon type="status-sent" />
                </Link>
              </Tooltip>
            </ModuleAction>

            <ModuleAction module="cohorts">
              <Tooltip placement="top-end" title="Send notification">
                <Button
                  type="text"
                  size="small"
                  onClick={() => setSendNotificationCohortId(id)}
                >
                  <Icon type="notifications" />
                </Button>
              </Tooltip>
            </ModuleAction>
          </Space>
        </div>
      );
    },
  },
];

import { cohortSuffixMappings } from "features/cohorts/utils";
import { MapType } from "types";

export const capitalize = (value: string) =>
  value[0].toUpperCase() + value.slice(1).toLowerCase();

export const getParamComponentLabel = (
  key: string,
  keyMap: MapType<string, string>,
) => {
  return key.includes("__")
    ? `${keyMap[key.split("__")?.[0]]} 
         
        ${cohortSuffixMappings?.[
          key.split("__")?.[1] as keyof typeof cohortSuffixMappings
        ]?.label?.toLowerCase?.()}`
    : keyMap[key];
};

export const formatCohortDataValues = (
  values: string[],
  map: Record<string, Record<string, string>>,
  key: string,
): string => {
  return values
    .map((value) => {
      if (map[key] && map[key][value]) {
        return map[key][value];
      } else {
        const parts = value?.split("__");
        return parts.join("-");
      }
    })
    .join(", ");
};

import { Input, Label, Space } from "ebs-design";
import { GenericObject } from "types";
import { getCohortFieldsParts } from "../utils";

export interface Props {
  formValue: GenericObject;
  option: GenericObject;
  onChange: (value: GenericObject) => void;
}

export const InputNumberElement = ({ formValue, option, onChange }: Props) => {
  const onChangeNumber = (date: number | string) => {
    const updatedFormValue = { ...formValue };
    for (const key in updatedFormValue) {
      if (key.includes(getCohortFieldsParts(option?.value)?.prefix)) {
        updatedFormValue[key] = [];
      }
    }
    if (date) {
      updatedFormValue[option?.value] = [date];
      onChange(updatedFormValue);
    } else {
      onChange([]);
    }
  };

  return (
    <Space justify="space-between">
      <Label text="Count:" />

      <Input
        isClearable
        type="number"
        value={formValue?.[option?.value]?.[0]}
        onChange={onChangeNumber}
      />
    </Space>
  );
};

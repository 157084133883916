import { subYears } from "date-fns";

import { CohortParameters, NumRangeStr } from "types";
import { dateToInputFormat } from "utils";

export const queryParamsFromCohortParams = (parameters?: CohortParameters) =>
  filterQuery({
    gender: parameters?.gender?.map((g) => g.toLowerCase()),
    ...queryParamsFromRanges("birthday", parameters?.age, (a) =>
      dateToInputFormat(subYears(new Date(), Number(a))),
    ),
    ...queryParamsFromRanges("revenue", parameters?.financial_metrics?.revenue),
    ...queryParamsFromRanges(
      "revenue_monthly",
      parameters?.financial_metrics?.revenue_monthly,
    ),
    ...queryParamsFromRanges(
      "paid_gifts_last_12_months",
      parameters?.financial_metrics?.paid_gifts_last_12_months,
    ),
    location: parameters?.location?.map((n) => n?.name?.toLowerCase()),
    ...queryParamsFromRanges("free_gifts", parameters?.free_gifts),
    ...queryParamsFromRanges("invites", parameters?.invites),
    favourite_brands: parameters?.favourite_brands?.map((b) => b.toLowerCase()),
    ...queryParamsFromRanges(
      "close_friends",
      parameters?.in_app_metrics?.close_friends,
    ),
    ...queryParamsFromRanges("sessions", parameters?.in_app_metrics?.sessions),
    ...queryParamsFromRanges(
      "special_days",
      parameters?.in_app_metrics?.special_days,
    ),
    notifications: parameters?.in_app_metrics?.notifications,
    interests: parameters?.interests,
    date_joined__gte: parameters?.in_app_metrics?.date_joined__gte,
    date_joined__lte: parameters?.in_app_metrics?.date_joined__lte,
    date_joined__range: parameters?.in_app_metrics?.date_joined__range,
    has_favorites_products: parameters?.in_app_metrics?.has_favorites_products,
    campaign_joined__gte: parameters?.campaign_metrics?.campaign_joined__gte,
    campaign_joined__lte: parameters?.campaign_metrics?.campaign_joined__lte,
    campaign_joined__range:
      parameters?.campaign_metrics?.campaign_joined__range,
    campaign_sent_go_go: parameters?.campaign_metrics?.campaign_sent_go_go,
    campaign_free_gifts_sent:
      parameters?.campaign_metrics?.campaign_free_gifts_sent,
    campaign_free_gifts_sent__range:
      parameters?.campaign_metrics?.campaign_free_gifts_sent__range,
    campaign_paid_gifts_sent:
      parameters?.campaign_metrics?.campaign_paid_gifts_sent,
    campaign_paid_gifts_sent__range:
      parameters?.campaign_metrics?.campaign_paid_gifts_sent__range,
    campaign_tier: parameters?.campaign_metrics?.campaign_tier,
    campaign_tier__range: parameters?.campaign_metrics?.campaign_tier__range,
  });

function queryParamsFromRanges(
  name: string,
  ranges?: NumRangeStr[],
  transform: (v: string) => string = (v) => v,
) {
  return {
    [`${name}__lt`]: ranges
      ?.filter((r) => !r.split("-")[0] && r.split("-")[1])
      .map((r) => r.split("-")[1])
      .map(transform),

    [`${name}__gt`]: ranges
      ?.filter((r) => r.split("-")[0] && !r.split("-")[1])
      .map((r) => r.split("-")[0])
      .map(transform),

    [`${name}__range`]: ranges
      ?.filter((r) => r.split("-")[0] && r.split("-")[1])
      .map((r) => r.split("-").map(transform).join("__")),
  };
}

function filterQuery(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      if (!value) return false;
      if (value instanceof Array && !value.length) return false;
      if (value instanceof Object && !Object.keys(value).length) return false;
      return true;
    }),
  );
}

export const getCohortFieldsParts = (key: string) => {
  const parts = key?.split("__");

  return {
    prefix: parts?.[0] || "",
    suffix: parts?.[1] || "",
  };
};

export const cohortSuffixMappings = {
  gte: { value: "__gte", label: "From date" },
  lte: { value: "__lte", label: "To date" },
  range: { value: "__range", label: "" },
};

export enum CohortStatusType {
  SYNC_START = "Sync start",
  SYNC_COMPLETE = "Sync complete",
}

import React from "react";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";

import {
  Button,
  Card,
  Form,
  Loader,
  Space,
  useForm,
  useNotify,
} from "ebs-design";
import { FormProps } from "ebs-design/dist/components/organisms/Form";

import {
  AlertErrors,
  Container,
  Icon,
  Tab,
  TabContent,
  TabProvider,
} from "components";
import { CohortEntity, CohortParameters, GenericObject } from "types";

import {
  UserMetricsTab,
  CohortFormSummary,
  BrandLikesTab,
  InterestsTab,
} from "./";

import { queryParamsFromCohortParams } from "../utils";
import { PotentialUsersTable } from "../PotentialUsersTable";
import { BusinessCompaniesTab } from "./BusinessCompaniesTab";

export interface CohortFormProps {
  values?: CohortEntity;
  cohortMutation: UseMutationResult<
    CohortEntity,
    AxiosError,
    Partial<CohortEntity>
  >;
  canMutate?: boolean;
  isLoading?: boolean;
  showForm?: boolean;
  errors?: (AxiosError | undefined | null)[];
  onSuccessMessage?: string;
}

export const CohortForm = ({
  values,
  errors,
  cohortMutation,
  onSuccessMessage,
  canMutate,
  isLoading,
  showForm = true,
}: CohortFormProps) => {
  const [form] = useForm();
  const notify = useNotify();

  const [parameters, setParameters] = React.useState<CohortParameters>();

  const setCohortFields = React.useCallback(
    (data: CohortEntity) => form.setFieldsValue({ ...data }),
    [form],
  );

  React.useEffect(() => {
    values && setCohortFields(values);
    setParameters(form.getFieldValue(["parameters"]));
  }, [form, setCohortFields, values]);

  const onFieldsChangeHandler: FormProps["onFieldsChange"] = (fields) => {
    for (const field of fields) {
      if ((field.name as string[])[0] === "parameters") {
        setParameters(form.getFieldValue("parameters"));
        return;
      }
    }
  };

  const onFinishHandler = (formValues: GenericObject) => {
    canMutate &&
      cohortMutation.mutate(
        {
          ...formValues,
          ...(Object.keys(queryParamsFromCohortParams(formValues.parameters))
            .length > 0 && {
            query_params: queryParamsFromCohortParams(formValues.parameters),
          }),
        },
        {
          onSuccess: (data) => {
            setCohortFields({ ...data });
            notify.success({
              title: "",
              description: onSuccessMessage,
            });
          },
        },
      );
  };

  return (
    <Container size="lg">
      <Loader loading={!!isLoading} />
      {errors?.map((e, i) => (
        <AlertErrors key={i} error={e} />
      ))}
      {errors?.length ? <div className="pady-2"></div> : null}
      {showForm && (
        <>
          <Form
            form={form}
            onFinish={onFinishHandler}
            onFieldsChange={onFieldsChangeHandler}
          >
            <div className="cohorts__form-layout">
              <TabProvider defaultValue="user_metrics">
                <Card className="cohorts__tabs-container">
                  <div className="cohorts__tabs">
                    <Tab tabKey="user_metrics" className="cohorts__tab">
                      User metrics
                    </Tab>
                    <Tab tabKey="interests" className="cohorts__tab">
                      Interests
                    </Tab>
                    <Tab tabKey="brand_likes" className="cohorts__tab">
                      Brand likes
                    </Tab>
                    <Tab tabKey="business_companies" className="cohorts__tab">
                      Business companies
                    </Tab>
                  </div>
                  <TabContent
                    tabKey="user_metrics"
                    className="cohorts__parameters-layout bg-grey-200"
                  >
                    <UserMetricsTab />
                  </TabContent>

                  <TabContent
                    className="cohorts__overflow-fix"
                    tabKey="interests"
                  >
                    <InterestsTab />
                  </TabContent>
                  <TabContent
                    tabKey="brand_likes"
                    className="cohorts__overflow-fix"
                  >
                    <BrandLikesTab />
                  </TabContent>
                  <TabContent
                    tabKey="business_companies"
                    className="cohorts__overflow-fix"
                  >
                    <BusinessCompaniesTab />
                  </TabContent>
                </Card>
              </TabProvider>
              <CohortFormSummary
                parameters={parameters}
                footer={
                  <Space justify="end">
                    <Button
                      onClick={() => form.submit()}
                      type="primary"
                      prefix={<Icon type="edit" />}
                      disabled={!canMutate}
                      loading={cohortMutation.isLoading}
                    >
                      Save
                    </Button>
                  </Space>
                }
              />
            </div>
          </Form>

          <div className="pady-4"></div>
          <PotentialUsersTable parameters={parameters} />
        </>
      )}
    </Container>
  );
};

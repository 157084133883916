import {
  ageRangeNameMap,
  finantialMetricsRangeNameMap,
  freeGiftsRangeNameMap,
  genderNameMap,
  inAppMetricsRangeNameMap,
  campaignMetricsRangeNameMap,
  invitesRangeNameMap,
} from "../nameMaps";

export const genderOptions = Object.entries(genderNameMap).map((e) => ({
  text: e[1],
  value: e[0],
}));

export const ageOptions = Object.entries(ageRangeNameMap).map((e) => ({
  text: e[1],
  value: e[0],
}));

export const financialMetricsOptions = Object.fromEntries(
  Object.entries(finantialMetricsRangeNameMap).map((e) => [
    e[0],
    Object.entries(e[1]).map((e) => ({
      text: e[1],
      value: e[0],
    })),
  ]),
);

export const freeGiftsOptions = Object.entries(freeGiftsRangeNameMap).map(
  (e) => ({
    text: e[1],
    value: e[0],
  }),
);

export const invitesOptions = Object.entries(invitesRangeNameMap).map((e) => ({
  text: e[1],
  value: e[0],
}));

export const inAppMetricsOptions = Object.fromEntries(
  Object.entries(inAppMetricsRangeNameMap).map((e) => [
    e[0],
    Object.entries(e[1]).map((e) => ({
      text: e[1],
      value: e[0],
    })),
  ]),
);

export const campaignMetricsOptions = Object.fromEntries(
  Object.entries(campaignMetricsRangeNameMap).map((e) => [
    e[0],
    Object.entries(e[1]).map((e) => ({
      text: e[1],
      value: e[0],
    })),
  ]),
);

import {
  FinancialMetricsKey,
  Gender,
  InAppMetricsKey,
  CampaignMetricsKey,
  MapType,
} from "types";

export const genderNameMap: MapType<Gender, string> = {
  [Gender.MALE]: "Male",
  [Gender.FEMALE]: "Female",
  [Gender.NON_BINARY]: "Non binary",
  [Gender.OTHER]: "Other",
  [Gender.PREFER_NOT_TO_SAY]: "Prefer not to say",
};

export const ageRangeNameMap: MapType<string, string> = {
  // reverse because it's transformed to date
  "18-": "Under 18",
  "25-19": "19-25",
  "35-26": "26-35",
  "45-36": "36-45",
  "55-46": "46-55",
  "65-56": "56-65",
  "-65": "Over 65",
};

export const finantialMetricsRangeNameMap: MapType<
  FinancialMetricsKey,
  MapType<string, string>
> = {
  revenue_monthly: {
    "-5": "Under £5",
    "5-10": "£5-£10",
    "11-20": "£11-£20",
    "21-30": "£21-£30",
    "31-50": "£31-£50",
    "50-": "Over £50",
  },
  paid_gifts_last_12_months: {
    "-2": "Under 2",
    "2-5": "2-5",
    "6-10": "6-10",
    "11-20": "11-20",
    "21-30": "21-30",
    "31-40": "31-40",
    "41-": "Over 41",
  },
  revenue: {
    "-10": "Under £10",
    "10-50": "£10-£50",
    "51-100": "£51-£100",
    "101-150": "£101-£150",
    "151-200": "£151-£200",
    "201-300": "£201-£300",
    "300-": "Over £300",
  },
};

export const finantialMetricsKeyNameMap: MapType<
  FinancialMetricsKey,
  string
> = {
  revenue_monthly: "Monthly",
  paid_gifts_last_12_months: "Last 12 months",
  revenue: "Life time",
};

export const freeGiftsRangeNameMap: MapType<string, string> = {
  "-2": "Under 2",
  "2-4": "2-4",
  "5-8": "5-8",
  "9-10": "9-10",
  "10-": "Over 10",
};

export const invitesRangeNameMap: MapType<string, string> = {
  "-2": "Under 2",
  "2-4": "2-4",
  "5-8": "5-8",
  "9-10": "9-10",
  "10-": "Over 10",
};

export const inAppMetricsRangeNameMap: MapType<
  InAppMetricsKey,
  MapType<string, string>
> = {
  close_friends: {
    "-2": "Under 2",
    "2-5": "2-5",
    "6-10": "6-10",
    "11-20": "11-20",
    "21-30": "21-30",
    "31-40": "31-40",
    "41-": "Over 41",
  },
  special_days: {
    "-2": "Under 2",
    "2-5": "2-5",
    "6-10": "6-10",
    "11-20": "11-20",
    "21-30": "21-30",
    "31-40": "31-40",
    "41-": "Over 41",
  },
  sessions: {
    "-2": "Under 2",
    "2-5": "2-5",
    "6-10": "6-10",
    "11-20": "11-20",
    "21-30": "21-30",
    "31-40": "31-40",
    "41-": "Over 41",
  },
  notifications: {
    true: "On",
    false: "Off",
  },
  date_joined: {
    date_joined__gte: "date",
    date_joined__lte: "date",
    // date_joined__range: "range",
  },
  has_favorites_products: {
    true: "Yes",
    false: "No",
  },
};

export const inAppMetricsKeyNameMap: MapType<InAppMetricsKey, string> = {
  close_friends: "Close friends",
  special_days: "Special days",
  sessions: "Sessions",
  notifications: "Notifications",
  date_joined: "Date joined",
  has_favorites_products: "Has favorites products",
};

export const campaignMetricsRangeNameMap: MapType<
  CampaignMetricsKey,
  MapType<string, string>
> = {
  campaign_joined: {
    campaign_joined__gte: "date",
    campaign_joined__lte: "date",
    // campaign_joined__range: "range",
  },
  campaign_sent_go_go: {
    true: "Yes",
    false: "No",
  },
  campaign_free_gifts_sent: {
    campaign_free_gifts_sent: "number",
    // campaign_free_gifts_sent__range: "range-number",
  },
  campaign_paid_gifts_sent: {
    campaign_paid_gifts_sent: "number",
    // campaign_paid_gifts_sent__range: "range-number",
  },
  campaign_tier: {
    campaign_tier: "number",
    // campaign_tier__range: "range-number",
  },
};

export const campaignMetricsKeyNameMap: MapType<CampaignMetricsKey, string> = {
  campaign_joined: "Campaign joined",
  campaign_sent_go_go: "Campaign sent go go",
  campaign_free_gifts_sent: "Campaign free gifts sent",
  campaign_paid_gifts_sent: "Campaign paid gifts sent",
  campaign_tier: "Campaign tier",
};

export const dateInputKeys = [
  "campaign_joined__lte",
  "campaign_joined__gte",
  "date_joined__lte",
  "date_joined__gte",
];

export const rangeInputKeys = ["campaign_joined__range", "date_joined__range"];

export const countInputKeys = [
  "campaign_free_gifts_sent",
  "campaign_paid_gifts_sent",
  "campaign_tier",
];

export const countRangeInputKeys = [
  "campaign_free_gifts_sent__range",
  "campaign_paid_gifts_sent__range",
  "campaign_tier__range",
];

import {
  ApiQueryType,
  CohortEntity,
  CohortGiftEntity,
  CohortsStats,
  Results,
  SendNotificationType,
  UserProfileEntity,
} from "types";
import { defaultQueryLimit } from "utils";

import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const cohorts = {
  getList: async (query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/cohorts/`, query);
    const { data } = await apiAxios.get<Results<CohortEntity>>(url);
    return data;
  },
  getById: async (id: number) => {
    const url = stringifyUrl(`/cohorts/${id}/`);
    const { data } = await apiAxios.get<CohortEntity>(url);
    return data;
  },
  getStats: async () => {
    const { data } = await apiAxios.get<CohortsStats>(`/cohorts/stats/`);
    return data;
  },
  getUsers: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/cohorts/users/`, query);
    const { data } = await apiAxios.get<Results<UserProfileEntity>>(url);
    return data;
  },
  getGifts: async (id: number, query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/cohorts/${id}/gifts/`, query);
    const { data } = await apiAxios.get<Results<CohortGiftEntity>>(url);
    return data;
  },
  post: async (cohort: Partial<CohortEntity>) => {
    const { data } = await apiAxios.post<CohortEntity>(`/cohorts/`, cohort);
    return data;
  },
  patch: async (id: number, cohort: Partial<CohortEntity>) => {
    const { data } = await apiAxios.patch<CohortEntity>(
      `/cohorts/${id}/`,
      cohort,
    );
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/cohorts/${id}/`);
    return data;
  },
  sendNotification: async (
    id?: number,
    notification?: SendNotificationType,
  ) => {
    const { data } = await apiAxios.post<SendNotificationType>(
      `/cohorts/${id}/send_notification/`,
      notification,
    );
    return data;
  },
};

export enum NotificationsType {
  EMAIL = "email",
  PUSH = "push",
}

export interface SendNotificationType {
  title: string;
  body: string;
  types: NotificationsType;
}
